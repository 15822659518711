import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-homethree-banner',
    templateUrl: './homethree-banner.component.html',
    styleUrls: ['./homethree-banner.component.scss'],
})
export class HomethreeBannerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    mainBannerItem = [
        {
            subTitle: 'NEW INSPIRATION 2022',
            title: 'Exotic Furniture, Avail Now !',
            paragraph:
                'We shall help you set your decor with exotic furnitures at your reliable budget. Visit Our Showroom for featuring more choices.',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'PUT YOUR QUERY',
            buttonLink: 'contact',
            image: 'assets/img/home/main-banner-1.webp',
        },
        {
            subTitle: 'NEW INSPIRATION 2022',
            title: 'Royal Furnitures, featured !!',
            paragraph:
                'We do design royal furnitures based on your requirement and styling you want !',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'PUT YOUR QUERY',
            buttonLink: 'contact',
            image: 'assets/img/home/main-banner-2.webp',
        },
        {
            subTitle: 'NEW INSPIRATION 2022',
            title: 'Bringing the Trend Here !',
            paragraph:
                'We are also master in designing the trending furnitures, the latest showcase is here for you, have a glance',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'PUT YOUR QUERY',
            buttonLink: 'contact',
            image: 'assets/img/home/main-banner-3.webp',
        },
    ];
    bannerSocialLinks = [
        {
            icon: 'bx bxl-facebook',
            link: 'https://www.facebook.com/babafurniture786',
        },
        // {
        //     icon: 'bx bxl-twitter',
        //     link: '#',
        // },
        // {
        //     icon: 'bx bxl-linkedin',
        //     link: '#',
        // },
        {
            icon: 'bx bxl-instagram',
            link: 'https://www.instagram.com/babafurniturejsr/',
        },
    ];

    homeSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin: 5,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        },
    };
}
