import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    pageTitle = [
        {
            bgImage: 'assets/img/about/about.webp',
            title: 'About Us',
        },
    ];

    aboutContent = [
        {
            subTitle: `ABOUT US`,
            title: `BABA FURNITURE - THE ONLY BRANCH IN JAMSHEDPUR SERVING SINCE 20 YEARS TRUST.`,
            paragraph: `We Are Happy To Serve Our Customers With Pride, Best Furnitures As Per Their Requirement. We Are Bringing Up The Best Furniture At Jamshedpur That Gives Your Interior A Different And Extraordinary Look.
            `,
            content: [
                {
                    title: `Our Goal`,
                    paragraph: `We Aim To Fulfill Our Customers Want. Interior Furnishing, Office Furniture, Room Decor, Etc....`,
                },
                {
                    title: `Our Mission`,
                    paragraph: `To Deliver The Right Product At Right Time With The Best Quality. Delivering Quality Since We Are In The Market.`,
                },
                {
                    title: `Our Vision`,
                    paragraph: `We Do Bring Latest Furnitures And Do Keep The Royalty In Those Furnitures As Well. We Keep The Trend Alive Along With Ancient Furnitures Collection`,
                },
            ],
        },
    ];
    aboutImage = [
        {
            img: 'assets/img/about/about-1.webp',
        },
        {
            img: 'assets/img/about/about-2.webp',
        },
    ];
}
