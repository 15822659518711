import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss'],
})
export class FacilityStyleOneComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    facilityContent = [
        {
            icon: 'bx bx-shopping-bag',
            title: 'Shop Now',
            paragraph:
                'Get perfect and stylish furniture for your home decor. ',
        },
        {
            icon: 'bx bxs-truck',
            title: 'Delivering at Your Place',
            paragraph:
                'After you choose your furniture, we shall deliver it at your place within no time.',
        },
        {
            icon: 'bx bx-info-square',
            title: 'Return Policy',
            paragraph:
                'We shall help you with this once we connect you on call or directly at our showroom, after looking into your issues.',
        },
        {
            icon: 'bx bx-check-shield',
            title: 'Secured Payments',
            paragraph:
                'Payment mode shall be secure and we shall provide you the payment receipt immediately.',
        },
    ];
    facilityImage = [
        {
            img: 'assets/img/home/delivery.webp',
        },
    ];
}
