import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-living-room-products',
    templateUrl: './living-room-products.component.html',
    styleUrls: ['./living-room-products.component.scss'],
})
export class LivingRoomProductsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    roomsContent = [
        {
            bgImage: 'assets/img/interior/living.webp',
            title: 'Living Room',
            paragraph:
                "We shall help you in fixing the furniture at best corners of your living room. Get these sections available and it's done.",
            list: [
                {
                    title: 'Opt for light colours',
                },
                {
                    title: 'Think carefully about flooring',
                },
                {
                    title: 'Add a sofa',
                },
                {
                    title: 'Wall features',
                },
                {
                    title: 'Keep the space cosy',
                },
            ],
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP LIVING ROOM',
            buttonLink: 'contact',
        },
    ];

    singleProductsBox = [
        {
            mainImg: 'assets/img/interior/living/luxury-sofa.webp',
            title: 'Luxury Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/living/modern-chair.webp',
            title: 'Modern Chair',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Upto 50% off!',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/living/modern-desk.webp',
            title: 'Modern Desk',
            newPrice: '$223.99',
            oldPrice: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/living/stylish-showcase.webp',
            title: 'Stylish Showcase',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Upto 50% off!',
            detailsLink: 'contact',
        },
    ];

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
        },
    };
}
