import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss'],
})
export class GalleryPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    pageTitle = [
        {
            bgImage: 'assets/img/gallery/gallery.webp',
            title: 'Gallery',
        },
    ];
    singleGalleryItem = [
        {
            img: 'assets/img/gallery/gallery1.webp',
        },
        {
            img: 'assets/img/gallery/gallery2.webp',
        },
        {
            img: 'assets/img/gallery/gallery3.webp',
        },
        {
            img: 'assets/img/gallery/gallery4.webp',
        },
        {
            img: 'assets/img/gallery/gallery5.webp',
        },
        {
            img: 'assets/img/gallery/gallery6.webp',
        },
        // {
        //     img: 'assets/img/gallery/gallery7.webp',
        // },
        // {
        //     img: 'assets/img/gallery/gallery8.webp',
        // },
        // {
        //     img: 'assets/img/gallery/gallery9.webp',
        // },
    ];
}
