import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-fab',
  templateUrl: './whatsapp-fab.component.html',
  styleUrls: ['./whatsapp-fab.component.scss']
})
export class WhatsappFabComponent {
  openWhatsApp() {
    window.open('https://wa.me/+917277997299', '_blank'); // Replace 'your-number' with your actual WhatsApp number
  }
}
