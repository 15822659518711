<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{ bgClass }}">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <a routerLink="/" class="logo d-inline-block">
            <img src="assets/img/logo.webp" alt="image" />
          </a>
          <p>We are serving in Jamshedpur, since 2001.</p>
          <ul class="social-links">
            <li>
              <a
                href="https://www.facebook.com/babafurniture786"
                target="_blank"
                ><i class="bx bxl-facebook"></i
              ></a>
            </li>
            <!-- <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li> -->
            <li>
              <a
                href="https://www.instagram.com/babafurniturejsr/"
                target="_blank"
                ><i class="bx bxl-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Help</h3>

          <ul class="links-list">
            <li>
              <a routerLink="/customer-service">Privacy Policy</a>
            </li>
            <!-- <li>
                            <a routerLink="/customer-service">Return Policy</a>
                        </li> -->
            <li>
              <a routerLink="/customer-service">Terms & Conditions</a>
            </li>
            <li><a routerLink="/interior">Interior</a></li>
            <li><a routerLink="/gallery">Gallery</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Support</h3>

          <ul class="links-list">
            <li><a routerLink="/about">About Us</a></li>
            <li><a routerLink="/contact">Contact Us</a></li>
            <!-- <li><a routerLink="/shop-full-width-1">Woman</a></li>
                        <li>
                            <a routerLink="/shop-full-width-1">Accessories</a>
                        </li>
                        <li><a routerLink="/shop-full-width-1">Speakers</a></li>
                        <li><a routerLink="/shop-full-width-1">Shoes</a></li> -->
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Contacts</h3>

          <ul class="footer-contact-info">
            <li>
              Address: Block No. 2, Shastri Nagar, Kadma, Jamshedpur, India
            </li>
            <li>Branch: The Turning Point, Mango Chowk, Jamshedpur</li>
            <li>
              Email:
              <a href="mailto:babafurniture786@gmail.com"
                >babafurniture786@gmail.com</a
              >
            </li>
            <li>
              Mobile: <a href="tel:+917277997299">727 799 7299</a>,&nbsp;<a
                href="tel:+918292747473"
                >+91 829 2747473</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <p>
            Copyright @2022
            <strong>Baba Furniture</strong> Developed By :
            <a href="https://teckat.com/" target="_blank">Teckat.com</a>
          </p>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <ul>
            <li>
              <a routerLink="/customer-service">Privacy Policy</a>
            </li>
            <li>
              <a routerLink="/customer-service">Terms & Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<div
  class="sidebarModal modal right fade"
  id="sidebarModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <button type="button" class="close" data-bs-dismiss="modal">
        <i class="bx bx-x"></i>
      </button>
      <div class="modal-body">
        <div class="logo">
          <a href="index.html" class="d-inline-block"
            ><img src="assets/img/logo.webp" alt="image"
          /></a>
        </div>
        <div class="instagram-list">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery1.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery2.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery3.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery4.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery5.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
              <div class="box">
                <img src="assets/img/gallery/gallery6.jpg" alt="image" />
                <i class="bx bxl-instagram"></i>
                <a href="#" target="_blank" class="link-btn"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar-contact-info">
          <h2>
            <a href="tel:+917277997299">+91 727 799 7299</a>
            <!-- <span>OR</span> -->
            <!-- <a href="mailto:babafurniture786@gmail.com"
                            >babafurniture786@gmail.com</a
                        > -->
          </h2>
        </div>
        <ul class="social-list">
          <li><span>Follow Us On:</span></li>
          <li>
            <a href="https://www.facebook.com/babafurniture786" target="_blank"
              ><i class="bx bxl-facebook"></i
            ></a>
          </li>
          <!-- <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="bx bxl-youtube"></i
                        ></a>
                    </li> -->
          <li>
            <a
              href="https://www.instagram.com/babafurniturejsr/"
              target="_blank"
              ><i class="bx bxl-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->
<div
  class="modal fade productsQuickView"
  id="productsQuickView"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true"><i class="bx bx-x"></i></span>
      </button>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="products-image">
            <img src="assets/img/products/products1.jpg" alt="image" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="products-content">
            <h3>Rivet Farr Lotus</h3>
            <div class="price">
              <span class="new-price">$150.00</span>
            </div>
            <div class="products-review">
              <div class="rating">
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
              </div>
              <a href="#" class="rating-count">3 reviews</a>
            </div>
            <p>
              Nemo malesuada animi consectetur, cras consectetuer laborum
              tenetur, cum, lacus nemo imperdiet facilisis! Aute metus, lorem
              primis anim. Eros dolorem.
            </p>
            <ul class="products-info">
              <li><span>Vendor:</span> <a href="#">Lereve</a></li>
              <li>
                <span>Availability:</span>
                <a href="#">In stock (7 items)</a>
              </li>
              <li>
                <span>Products Type:</span>
                <a href="#">Chair</a>
              </li>
              <li><span>Categories:</span> <a href="#">Chair</a></li>
            </ul>
            <div class="products-add-to-cart">
              <div class="input-counter">
                <span class="minus-btn"><i class="bx bx-minus"></i></span>
                <input type="text" value="1" min="1" />
                <span class="plus-btn"><i class="bx bx-plus"></i></span>
              </div>
              <button type="submit" class="default-btn">
                <i class="bx bx-cart"></i> ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
<div
  class="productsFilterModal modal right fade"
  id="productsFilterModal"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true"><i class="bx bx-x"></i> Close</span>
      </button>
      <div class="modal-body">
        <aside class="widget-area p-0">
          <div class="widget widget_search">
            <form class="search-form">
              <label>
                <input
                  type="search"
                  class="search-field"
                  placeholder="Search..."
                />
              </label>
              <button type="submit">
                <i class="bx bx-search-alt"></i>
              </button>
            </form>
          </div>
          <div class="widget widget_categories">
            <h3 class="widget-title">Categories</h3>
            <ul>
              <li>
                <a routerLink="/shop-left-sidebar-1">Accessories</a
                ><span class="post_count">(5)</span>
              </li>
              <li>
                <a routerLink="/shop-left-sidebar-1">Men</a
                ><span class="post_count">(7)</span>
              </li>
              <li>
                <a routerLink="/shop-left-sidebar-1">Women</a
                ><span class="post_count">(3)</span>
              </li>
              <li>
                <a routerLink="/shop-left-sidebar-1">Sofa</a
                ><span class="post_count">(2)</span>
              </li>
              <li>
                <a routerLink="/shop-left-sidebar-1">Uncategorized</a
                ><span class="post_count">(8)</span>
              </li>
            </ul>
          </div>
          <div class="widget widget_colors">
            <h3 class="widget-title">Filter By Color</h3>
            <ul>
              <li>
                <a href="javascript:void(0)">
                  <span class="gray">Gray</span>
                  <span>Gray</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="green">Green</span>
                  <span>Green</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="red">Red</span>
                  <span>Red</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="black">Black</span>
                  <span>Black</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="brown">Brown</span>
                  <span>Brown</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="widget widget_price_filter">
            <h3 class="widget-title">Filter by Price</h3>
            <div class="collection_filter_by_price">
              <input
                class="js-range-of-price"
                type="text"
                data-min="0"
                data-max="1055"
                name="filter_by_price"
                data-step="10"
              />
            </div>
          </div>
          <div class="widget widget_top_rated_products">
            <h3 class="widget-title">Our Best Sellers</h3>
            <article class="item">
              <a routerLink="/slider-product" class="thumb"
                ><span class="fullimage cover bg1" role="img"></span
              ></a>
              <div class="info">
                <h4 class="title usmall">
                  <a routerLink="/slider-product">Long Sleeve Leopard</a>
                </h4>
                <div class="star-rating">
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                </div>
                <span class="price">$150.00</span>
              </div>
            </article>
            <article class="item">
              <a routerLink="/slider-product" class="thumb"
                ><span class="fullimage cover bg2" role="img"></span
              ></a>
              <div class="info">
                <h4 class="title usmall">
                  <a routerLink="/slider-product">Causal V-Neck Soft</a>
                </h4>
                <div class="star-rating">
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star-half"></i>
                </div>
                <span class="price">$150.00</span>
              </div>
            </article>
            <article class="item">
              <a routerLink="/slider-product" class="thumb"
                ><span class="fullimage cover bg3" role="img"></span
              ></a>
              <div class="info">
                <h4 class="title usmall">
                  <a routerLink="/slider-product">Yidarton Women's Comfy</a>
                </h4>
                <div class="star-rating">
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bx-star"></i>
                </div>
                <span class="price">$150.00</span>
              </div>
            </article>
            <article class="item">
              <a routerLink="/slider-product" class="thumb"
                ><span class="fullimage cover bg4" role="img"></span
              ></a>
              <div class="info">
                <h4 class="title usmall">
                  <a routerLink="/slider-product">Womens Tops Color</a>
                </h4>
                <div class="star-rating">
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bxs-star"></i>
                  <i class="bx bx-star"></i>
                  <i class="bx bx-star"></i>
                </div>
                <span class="price">$150.00</span>
              </div>
            </article>
          </div>
        </aside>
      </div>
    </div>
  </div>
</div>
<!-- End Sidebar Modal Area -->
