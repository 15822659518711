import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-trending-products',
    templateUrl: './trending-products.component.html',
    styleUrls: ['./trending-products.component.scss'],
})
export class TrendingProductsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    sectionTitle = [
        {
            title: "What's New Here !!",
            paragraph:
                'We are glancing the latest furnitures of the week. Have a look, make a call, get your quote, buy best furnitures !',
        },
    ];
    singleProductsBox = [
        {
            mainImg: 'assets/img/home/new/new-1.webp',
            title: 'Ergonomic Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product',
        },
        {
            mainImg: 'assets/img/home/new/new-2.webp',
            title: 'Elegant drawing Chair',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product',
        },
        {
            mainImg: 'assets/img/home/new/new-3.webp',
            title: 'Modern Dining',
            newPrice: '$223.99',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product',
        },
        {
            mainImg: 'assets/img/home/new/new-4.webp',
            title: 'Office Furnitures',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: '',
            detailsLink: 'simple-product',
        },
        {
            mainImg: 'assets/img/home/new/new-5.webp',
            title: 'Luxury Beds',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: '',
            detailsLink: 'simple-product',
        },
    ];

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
        },
    };
}
