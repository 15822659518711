import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-discount',
    templateUrl: './discount.component.html',
    styleUrls: ['./discount.component.scss'],
})
export class DiscountComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    discountContent = [
        {
            title: 'The Choice of Being Perfect',
            discount: 'Luxury Furnitures',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'PUT YOUR QUERY',
            buttonLink: 'contact',
        },
    ];
    discountImage = [
        {
            mainImg: 'assets/img/home/home-1.webp',
            discountParcentImg: 'assets/img/discount.png',
        },
    ];
}
