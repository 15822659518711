import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-categories',
    templateUrl: './homeone-categories.component.html',
    styleUrls: ['./homeone-categories.component.scss'],
})
export class HomeoneCategoriesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    singleCategoriesBigBox = [
        {
            img: 'assets/img/home/recent.webp',
            title: 'Recent Products',
            buttonText: 'Take a Glance',
            link: 'gallery',
        },
    ];
    singleCategoriesBox = [
        {
            img: 'assets/img/home/new-design.webp',
            title: 'New Design',
            buttonText: 'Take a Glance',
            link: 'gallery',
        },
        {
            img: 'assets/img/home/minimal-sofa.webp',
            title: 'Minimal Sofa',
            buttonText: 'Take a Glance',
            link: 'gallery',
        },
    ];
}
