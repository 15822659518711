import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss'],
})
export class WhyChooseUsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    whyChooseContent = [
        {
            subTitle: 'WHY CHOOSE US',
            title: 'Best Furniture Showroom in Jamshedpur',
            paragraph:
                'Visit Our Showroom and Explore the best furnishings, from modular kitchen to dining tables that makes your interior more adoring.',
            content: [
                {
                    title: 'Huge Collection',
                    paragraph:
                        'All new collection and worth price that gives you perfect choice for your interior. Various types of furniture available.',
                },
                {
                    title: 'We Have No Other Branch',
                    paragraph:
                        'Baba Furniture, the only branch in Jamshedpur at Kadma. Visit Our Showroom, you will love the collection.',
                },
                {
                    title: 'Fastest Delivery ',
                    paragraph:
                        'After you purchase the product, we shall help you with fastest delivery within the scheduled time.',
                },
            ],
        },
    ];
    whyChooseImage = [
        {
            img: 'assets/img/about/about-3.webp',
        },
    ];
}
