import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  public isDisabled = false;
  public form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  constructor(
    private appService: AppService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  pageTitle = [
    {
      bgImage: 'assets/img/contact.webp',
      title: 'Contact Us',
    },
  ];

  public async onSend() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      alert('Invalid form');
      return;
    }
    this.isDisabled = true;
    await this.spinner.show();
    this.appService.createContact(this.form.value).subscribe(
      () => {
        alert('Save Successfully');
        this.form.reset();
      },
      (err) => {
        confirm('Please Try After Some Time.');
        console.log(err);
      },
      () => {
        this.isDisabled = false;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }
    );
  }

  get name(): AbstractControl {
    return this.form.controls['name'];
  }
  get email(): AbstractControl {
    return this.form.controls['email'];
  }
  get phone(): AbstractControl {
    return this.form.controls['phone'];
  }
  get subject(): AbstractControl {
    return this.form.controls['subject'];
  }
  get message(): AbstractControl {
    return this.form.controls['message'];
  }
}
