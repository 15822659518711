import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-deal-in-this-week',
    templateUrl: './deal-in-this-week.component.html',
    styleUrls: ['./deal-in-this-week.component.scss'],
})
export class DealInThisWeekComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    dealOfTheWeekContent = [
        {
            title: 'Count the Number of People, Then Choose your Furniture !!',
            paragraph:
                'We are here to make your requirement our priority, find the right furniture that makes you available with right space.',
            discountPercent: 'Upto 50% OFF',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'CONTACT US',
            buttonLink: 'contact',
        },
    ];
    dealOfTheWeekImage = [
        {
            img: 'assets/img/home/home-2.webp',
        },
    ];
}
