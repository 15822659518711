<app-navbar-style-three></app-navbar-style-three>

<app-homethree-categories></app-homethree-categories>

<section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section>

<section class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</section>

<app-facility-style-one></app-facility-style-one>

<section class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</section>

<app-discount></app-discount>

<app-testimonials></app-testimonials>

<app-blog></app-blog>

<app-partner></app-partner>
