<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer>
<app-whatsapp-fab></app-whatsapp-fab>
<ngx-scrolltop *ngIf="!(location == '/coming-soon')"></ngx-scrolltop>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="line-scale-pulse-out-rapid"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>
