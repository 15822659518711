import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    videoContent = [
        {
            bgImage: 'assets/img/about/youtube.webp',
            title: 'Play the Video',
            paragraph:
                "All Good When Furniture Collection Goes Right to Your Place. Let it be office or home, it's a matter of choice. Have a glance.",
            videoLink: '#',
        },
    ];
}
