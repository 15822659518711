<app-navbar-style-three></app-navbar-style-three>

<app-homethree-banner></app-homethree-banner>

<app-discount></app-discount>

<app-homeone-categories></app-homeone-categories>

<app-deal-in-this-week></app-deal-in-this-week>

<div class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</div>

<app-facility-style-one></app-facility-style-one>

<app-testimonials></app-testimonials>
<!-- <app-partner></app-partner> -->
