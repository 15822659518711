import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    sectionTitle = [
        {
            subTitle: 'TESTIMONIALS',
            title: 'What Clients Say About Us',
        },
    ];
    singleTestimonialsItem = [
        {
            feedback:
                'If you are looking to customize a sofa, bed, dining table, lounger.. This is the place to be at. I explored a lot of places in jamshedpur to get a sofa/lounger. Ended my search at baba furniture.The owner - Mrs. Firdaus is so helpful in deciding the kind of design you want. Right from scratch to end. She is with you with a smile on her face. Wanna buy furniture, BABA furniture is the place..',
            authorImg:
                'https://lh3.googleusercontent.com/a-/AOh14GgHYSINf99dvdZktNLx2vK1SABxHijfY-1sihDuNA=w45-h45-p-rp-mo-ba4-br100',
            authorName: 'Mr. Khamb VBlog',
            authorDesignation: 'Local Guide',
        },
        {
            feedback:
                "If you want sagwan wood furniture. Then don't visit there. Because they don't keep furniture of sagwan. Most or we can all, the furniture are made of ply. Looks are good. Price is high . Discount available . But at the same price better to go for Sagwan or teak wood.",
            authorImg:
                'https://lh3.googleusercontent.com/a-/AOh14Gjmbtmnh_8exAjBHbCu-CSFwAVfSAqINXS1RJiV=w75-h75-p-rp-mo-ba3-br100',
            authorName: 'Jaya Das',
            authorDesignation: 'Local Guide',
        },
        {
            feedback:
                'One of the best furniture house in and around Jamshedpur. Great collection with quality products.',
            authorImg:
                'https://lh3.googleusercontent.com/a/AATXAJyR_uhSzXo5JuXarx5rlEs6XdSxTNNX5KUYTj3z=w75-h75-p-rp-mo-ba4-br100',
            authorName: 'Javed jamal',
            authorDesignation: 'Local Guide',
        },
    ];

    testimonialsSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay: true,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        },
    };
}
