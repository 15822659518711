import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-bedroom-products',
    templateUrl: './bedroom-products.component.html',
    styleUrls: ['./bedroom-products.component.scss'],
})
export class BedroomProductsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    roomsContent = [
        {
            bgImage: 'assets/img/interior/bedroom.webp',
            title: 'Bedroom',
            paragraph:
                'The size of the room should not matter when you have the overall idea. We have best ideas to your bedroom as well.',
            list: [
                {
                    title: 'Opt for subtle colours',
                },
                {
                    title: 'Think about the visual weight of your furniture.',
                },
                {
                    title: 'Remember the ceiling.',
                },
                {
                    title: 'Layer your lighting.',
                },
                {
                    title: 'Keep the space cosy',
                },
            ],
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP BEDROOM',
            buttonLink: 'contact',
        },
    ];

    singleProductsBox = [
        {
            mainImg: 'assets/img/interior/bedroom/framed-dressing.webp',
            title: 'Framed Dressing',
            newPrice: '$150.00',
            oldPrice: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/bedroom/modern-wardrobe.webp',
            title: 'Modern Wardrobe',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/bedroom/mounted-racks.webp',
            title: 'Mounted Racks',
            newPrice: '$223.99',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/bedroom/royal-lcd-showcase.webp',
            title: 'Royal LCD Showcase',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: '',
            detailsLink: 'contact',
        },
    ];

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
        },
    };
}
