import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-kitchen-room-products',
    templateUrl: './kitchen-room-products.component.html',
    styleUrls: ['./kitchen-room-products.component.scss'],
})
export class KitchenRoomProductsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}

    roomsContent = [
        {
            bgImage: 'assets/img/interior/kitchen.webp',
            title: 'Kitchen',
            paragraph:
                'Utensils, Cupboard fixings, Sink, Flooring Style, ultimately a modular kitchen. We have the spacing idea, you can take ab attempt to it.',
            list: [
                {
                    title: 'Choose Kitchen Colours Wisely.',
                },
                {
                    title: 'Ideal Counter Top Height.',
                },
                {
                    title: 'Ventilation is Key.',
                },
                {
                    title: "Don't Ignore Corner Spaces.",
                },
                {
                    title: 'Trash it Right.',
                },
            ],
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP KITCHEN',
            buttonLink: 'contact',
        },
    ];

    singleProductsBox = [
        {
            mainImg: 'assets/img/interior/kitchen/furnished-cooking-desk.webp',
            title: 'Furnished Cooking Desk',
            newPrice: '$220.00',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/kitchen/highstyle-showcase.webp',
            title: 'Highstyle Showcase',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Upto 50% off!',
            outOfStock: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/kitchen/luxury-dinning-table.webp',
            title: 'Luxury Dinning Table',
            newPrice: '$223.99',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'contact',
        },
        {
            mainImg: 'assets/img/interior/kitchen/modular-kitchen-set.webp',
            title: 'Modular Kitchen Set',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: '',
            detailsLink: 'contact',
        },
    ];

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
        },
    };
}
