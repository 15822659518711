<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle">
        <h2>{{ Content.title }}</h2>
        <p>{{ Content.paragraph }}</p>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template
                carouselSlide
                *ngFor="let Content of singleProductsBox"
            >
                <div class="single-products-box">
                    <div class="products-image">
                        <!-- <a
                            routerLink="/{{ Content.detailsLink }}"
                            class="d-block"
                        >
                            <img [src]="Content.mainImg" alt="image" />
                        </a> -->
                        <a class="d-block">
                            <img [src]="Content.mainImg" alt="image" />
                        </a>
                        <ul class="products-button">
                            <!-- <li>
                                <a><i class="bx bx-heart"></i></a>
                            </li> -->
                            <!-- <li>
                                <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productsQuickView"
                                    ><i class="bx bx-show-alt"></i
                                ></a>
                            </li> -->
                            <!-- <li>
                                <a
                                    href="{{ Content.mainImg }}"
                                    class="popup-btn"
                                    ><i class="bx bx-scan"></i
                                ></a>
                            </li> -->
                            <!-- <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li> -->
                        </ul>
                        <a class="add-to-cart-btn d-none d-lg-block">{{
                            Content.title
                        }}</a>
                    </div>
                    <div class="products-content d-block d-lg-none">
                        <h3>
                            <a>{{ Content.title }}</a>
                        </h3>
                        <div class="price">
                            <!-- <span class="new-price">{{Content.newPrice}}</span> -->
                            <!-- <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span> -->
                        </div>
                    </div>
                    <span class="on-sale" *ngIf="Content.sale">{{
                        Content.sale
                    }}</span>
                    <!-- <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div> -->
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
